














































































$hover-border-w: 2px;

.menu-entity--buttons-wrap {
  $overhang: 7px;

  position: absolute;
  // left: -$overhang;
  left: 0;
  justify-content: center;
  // width: calc(100% + #{$overhang * 2});
  width: 100%;
  height: 25px;

  .menu-entity--buttons {
    z-index: 929;
  }

  .border-line {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 2px;
    transition: background-color 0.1s ease;
  }

  &.top {
    top: 0;
    // top: ($hover-border-w * 4) - $hover-border-w;
    .menu-entity--buttons {
      transform: translateY(-50%);
    }
    .border-line {
      bottom: 100%;
    }
  }
  &.bottom {
    top: auto;
    bottom: 0;
    // bottom: ($hover-border-w * 4) - $hover-border-w;
    .menu-entity--buttons {
      transform: translateY(50%);
    }
    .border-line {
      top: 100%;
    }
  }

  .v-btn {
    &.add-hover {
      width: auto;
    }
  }
}

.entity-delete-btn {
  margin-left: 5px;
}
