



































.desc-placeholder {
  font-size: 0.8em;
  font-style: italic;
  line-height: 1em;
  color: darken(white, 25%);
  cursor: pointer;
}
.menu-item--description.was-deleted {
  flex: 0 0 100%;
}
