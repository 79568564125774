@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
.title-placeholder{font-size:.8rem;font-style:italic;line-height:1em;color:#bfbfbf;cursor:pointer}
.desc-placeholder{font-size:.8em;font-style:italic;line-height:1em;color:#bfbfbf;cursor:pointer}.menu-item--description.was-deleted{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%}
.item-attr.interactive:not(.static--forced){cursor:pointer}.item-attr{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.item-attr:hover:not(.unselected.static){opacity:.5}.item-attr--title{width:25px;height:25px;font-size:9px;line-height:23px;color:#940152;text-align:center;border:2px solid #940152;border-radius:100%}.placeholder--item-attr,.unselected{cursor:pointer;opacity:.8}.placeholder--item-attr .item-attr--title,.unselected .item-attr--title{color:gray;border-color:gray;border-style:dotted}.placeholder--item-attr:hover,.unselected:hover{opacity:1}.placeholder--item-attr:hover .item-attr--title,.unselected:hover .item-attr--title{border-style:solid}
.menu-item--prices{-webkit-box-flex:1;-ms-flex:auto;flex:auto;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;text-align:right}.item-price{-webkit-box-flex:0;-ms-flex:0 1 auto;flex:0 1 auto}.item-price.r-edit.empty-text{display:inline-block;min-width:8px;text-align:left;border-bottom:3px solid #6cbd7d}.divider{-webkit-box-flex:0;-ms-flex:0 1 auto;flex:0 1 auto}.price-placeholder{font-style:italic;color:#bfbfbf;cursor:pointer}
.is-dirty{min-height:10px}.attr-list-enter-active,.attr-list-leave-active{-webkit-transition:opacity .2s;transition:opacity .2s}.attr-list-enter,.attr-list-leave-active{opacity:0}.attr-list-enter-active{-webkit-transition-delay:0;transition-delay:0}.attr-list-move{-webkit-transition:-webkit-transform .2s;transition:-webkit-transform .2s;transition:transform .2s;transition:transform .2s, -webkit-transform .2s}
.menu-entity--buttons-wrap{position:absolute;left:0;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:25px}.menu-entity--buttons-wrap .menu-entity--buttons{z-index:929}.menu-entity--buttons-wrap .border-line{position:absolute;z-index:0;width:100%;height:2px;-webkit-transition:background-color .1s ease;transition:background-color .1s ease}.menu-entity--buttons-wrap.top{top:0}.menu-entity--buttons-wrap.top .menu-entity--buttons{-webkit-transform:translateY(-50%);transform:translateY(-50%)}.menu-entity--buttons-wrap.top .border-line{bottom:100%}.menu-entity--buttons-wrap.bottom{top:auto;bottom:0}.menu-entity--buttons-wrap.bottom .menu-entity--buttons{-webkit-transform:translateY(50%);transform:translateY(50%)}.menu-entity--buttons-wrap.bottom .border-line{top:100%}.menu-entity--buttons-wrap .v-btn.add-hover{width:auto}.entity-delete-btn{margin-left:5px}

/*# sourceMappingURL=chunk-96eda4ea.d7328603.css.map*/