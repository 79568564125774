


























































@import '@design';

.menu-item--prices {
  flex: auto;
  justify-content: flex-end;
  text-align: right;
}

.item-price {
  flex: 0 1 auto;
  &.r-edit.empty-text {
    display: inline-block;
    min-width: 8px;
    text-align: left;
    border-bottom: 3px solid $color-db-green;
  }
}
.divider {
  flex: 0 1 auto;
}

.price-placeholder {
  font-style: italic;
  color: darken(white, 25%);
  cursor: pointer;
}
